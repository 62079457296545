import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { countries } from "../common/countries";
import { useForm } from "react-hook-form";
import { updateDocRef } from "../../firebase_setup/firebase";
import { dbName } from "./constant";
import { getDoc, updateDoc } from "firebase/firestore/lite";

function ShippingAddressinfo({ onSubmit }) {
  const [addressInfo, setaddressInfo] = useState({});
  const [loader, setloader] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ values: addressInfo });

  console.log("addressInfo", addressInfo);

  useEffect(() => {
    const formdata = JSON.parse(localStorage.getItem("kycformData"));
    console.log(formdata);
    if (formdata?.shippingaddressInfo) {
      setaddressInfo(formdata?.shippingaddressInfo);
    }
  }, []);

  const formSubmit = async (data) => {
    setloader(true);
    // onSubmit("Address");
    const formdata = JSON.parse(localStorage.getItem("kycformData"));
    if (formdata?.docID) {
      console.log(formdata);
      const docref = updateDocRef(dbName, formdata.docID);
      if (docref) {
        const docSnap = (await getDoc(docref)).data();
        // const docSnap = await getDoc(docref);
        const respo = await updateDoc(docref, {
          shippingAddress: data,
          stepComplete: docSnap.stepComplete > 3 ? docSnap.stepComplete : 3,
        });
        console.log("res", respo);
        localStorage.setItem(
          "kycformData",
          JSON.stringify({
            ...formdata,
            shippingaddressInfo: data,
          })
        );
        onSubmit("IDProof");
      }
    }
    setloader(false);
  };
  return (
    <div className="partOne ">
      <h1 className="text-white">Shipping Address Info</h1>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label className="formLabel">
          Address Line 1<span className="spanTag">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          required
          name="shippingAddress1"
          //   value={""}
          isInvalid={
            errors.shippingAddress1?.type === "required" ? true : false
          }
          {...register("shippingAddress1", { required: true })}
          // onChange={(val) => {
          //   console.log(val.target.value);
          //   setaddressInfo((prevState) => ({
          //     ...prevState,
          //     address1: val.target.value,
          //   }));
          // }}
          placeholder="Enter address line 1"
        />
        <Form.Control.Feedback type="invalid">
          Please Enter Address Line 1
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formGridAddress2">
        <Form.Label className="formLabel">Address Line 2</Form.Label>
        <Form.Control
          required
          name="shippingAddress2"
          // value={addressInfo.adrline2}
          // onChange={(val) => {
          //   setaddressInfo((prevState) => ({
          //     ...prevState,
          //     adrline2: val.target.value,
          //   }));
          // }}
          isInvalid={
            errors.shippingAddress2?.type === "required" ? true : false
          }
          {...register("shippingAddress2")}
          placeholder="Enter address line 2"
        />
      </Form.Group>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label className="formLabel">
            City<span className="spanTag">*</span>
          </Form.Label>
          <Form.Control
            required
            // value={addressInfo.city}
            // onChange={(val) => {
            //   setaddressInfo((prevState) => ({
            //     ...prevState,
            //     city: val.target.value,
            //   }));
            // }}
            isInvalid={errors.shippingCity?.type === "required" ? true : false}
            {...register("shippingCity", { required: true })}
            placeholder="Enter City"
          />
          <Form.Control.Feedback type="invalid">
            Please Enter City Name
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState">
          <Form.Label className="formLabel">
            State<span className="spanTag">*</span>
          </Form.Label>
          <Form.Control
            required
            // value={addressInfo.state}
            // onChange={(val) => {
            //   setaddressInfo((prevState) => ({
            //     ...prevState,
            //     state: val.target.value,
            //   }));
            // }}
            isInvalid={errors.shippingState?.type === "required" ? true : false}
            {...register("shippingState", { required: true })}
            placeholder="Enter State"
          />
          <Form.Control.Feedback type="invalid">
            Please Enter State
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="formGridCountry">
          <Form.Label className="formLabel">
            Country<span className="spanTag">*</span>
          </Form.Label>
          <Form.Select
            placeholder="Choose"
            required
            // value={addressInfo.country}
            // onChange={(val) => {
            //   setaddressInfo((prevState) => ({
            //     ...prevState,
            //     country: val.target.value,
            //   }));
            // }}
            isInvalid={
              errors.shippingCountry?.type === "required" ? true : false
            }
            {...register("shippingCountry", { required: true })}
          >
            {countries.length > 0 &&
              countries.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
          </Form.Select>

          <Form.Control.Feedback type="invalid">
            Please Select Valid Country
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridZip">
          <Form.Label className="formLabel">
            Zip Code<span className="spanTag">*</span>
          </Form.Label>
          <Form.Control
            required
            // value={addressInfo.zip}
            // onChange={(val) => {
            //   setaddressInfo((prevState) => ({
            //     ...prevState,
            //     zip: val.target.value,
            //   }));
            // }}
            isInvalid={errors.shippingZip?.type === "required" ? true : false}
            {...register("shippingZip", { required: true })}
            placeholder="Enter Zip Code"
          />
          <Form.Control.Feedback type="invalid">
            Please Enter Valid Zip Code
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <div className="d-flex align-items-center justify-content-between mt-4">
        <Button variant="primary" onClick={() => onSubmit("Address")}>
          Previous
        </Button>
        <Button
          variant="primary"
          disabled={loader}
          onClick={handleSubmit(formSubmit)}
        >
          {loader ? (
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          ) : (
            <span> Save & Next</span>
          )}
        </Button>
      </div>
    </div>
  );
}

export default ShippingAddressinfo;
