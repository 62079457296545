// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc } from "firebase/firestore/lite";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC_Eg3KJV65BPndUBAfyOjyKaYRAlBA4dc",
  authDomain: "btcblack-94837.firebaseapp.com",
  projectId: "btcblack-94837",
  storageBucket: "btcblack-94837.appspot.com",
  messagingSenderId: "127116279823",
  appId: "1:127116279823:web:805d53b0ed36affa86a37a",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const kycDB = collection(db, "ecKyc");
export const kycTestDB = collection(db, "ecKycTest");
export const updateDocRef = (dbname, id) => doc(db, dbname, id);
