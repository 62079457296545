import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Basicinfo from "./Basicinfo";
import { useState } from "react";
import Addressinfo from "./Addressinfo";
import ShippingAddressinfo from "./ShippingAddressInfo";
import AddressProof from "./AddressProof";
import IDproof from "./IDproof";
import IntroInfo from "./IntroInfo";
import "./Form.css";
import { isMobile } from "react-device-detect";

export const Form = (props) => {
  const [formState, setformState] = useState("Intro");

  useEffect(() => {
    const todos = JSON.parse(localStorage.getItem("kycform"));
    console.log("todos", todos);
    if (todos) {
      setformState(todos.kycform);
    }
  }, []);

  // useEffect(() => {
  //   console.log("fo", formState);
  //   // localStorage.setItem("kycform", JSON.stringify(formState));
  // }, [formState]);

  const handleFormState = (val) => {
    console.log("val", val);
    localStorage.setItem("kycform", JSON.stringify({ kycform: val }));
    setformState(val);
  };

  const handleClearForm = () => {
    localStorage.removeItem("kycformData");
    localStorage.setItem("kycform", JSON.stringify({ kycform: "Intro" }));
    setformState("Intro");
  };

  return (
    <Container
      // style={{ height: "100vh" }}
      className={
        isMobile
          ? "justify-content-center d-flex align-items-center py-4 min-vh-100"
          : "justify-content-center d-flex align-items-center py-4 vh-100"
      }
    >
      <div
        className={
          isMobile ? "d-flex flex-column w-100" : "d-flex flex-column w-75"
        }
      >
        {formState === "Intro" ? (
          <IntroInfo onSubmit={handleFormState} />
        ) : formState === "Basic" ? (
          <Basicinfo onSubmit={handleFormState} />
        ) : formState === "Address" ? (
          <Addressinfo onSubmit={handleFormState} />
        ) : formState === "ShippingAddress" ? (
          <ShippingAddressinfo onSubmit={handleFormState} />
        ) : formState === "AddressProof" ? (
          <AddressProof
            onSubmit={handleFormState}
            handleClearForm={handleClearForm}
          />
        ) : formState === "IDProof" ? (
          <IDproof onSubmit={handleFormState} />
        ) : null}
        <div className="text-start pt-4">
          <button className="btn btn-dark clf-btn" onClick={handleClearForm}>
            Clear Form
          </button>
        </div>
      </div>
    </Container>
  );
};

export default Form;
