import { Button, Col, Form, Row } from "react-bootstrap";
import { countries, idTypes } from "../common/countries";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { updateDocRef } from "../../firebase_setup/firebase";
import { dbName, localStorageFormData } from "./constant";
import { getDoc, updateDoc } from "firebase/firestore/lite";

const IDProof = ({ setIdProofInfo, onSubmit }) => {
  const [formData, setformData] = useState({});
  const [loader, setloader] = useState(false);
  const [backform, setbackform] = useState("ShippingAddress");
  const {
    register,
    setValue,
    setError,
    clearErrors,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ values: formData });
  //   const [issueError, setIssueError] = useState(false);
  //   const [expiryError, setExpiryError] = useState(false);
  const storage = getStorage();

  useEffect(() => {
    const formdata = JSON.parse(localStorage.getItem("kycformData"));
    setbackform(
      formdata?.shipaddress === "yes" ? "Address" : "ShippingAddress"
    );
    if (formdata?.IDProof) {
      setformData(formdata?.IDProof);
    }
  }, []);

  // useEffect(() => {
  //   register("frontRead", { required: true });
  //   register("backRead", { required: true });
  //   // register("addressFrontRead", { required: true });
  //   // register("addressBackRead", { required: true });
  // }, [register]);

  //   const blurIssueError = (event) => {
  //     console.log("event", event);
  //     if (event == null || event == "" || event == undefined) {
  //       setIssueError(true);
  //     } else {
  //       setIssueError(false);
  //     }
  //   };

  //   const blurExpiryError = (event) => {
  //     console.log("event", event);
  //     if (event == null || event == "" || event == undefined) {
  //       setExpiryError(true);
  //     } else {
  //       setExpiryError(false);
  //     }
  //   };

  const fileFrontUpload = (file) => {
    if (file?.name) {
      var dateTime = Date.now();

      const storageRef = ref(
        storage,
        "images/kycDocuments/" + Math.floor(dateTime / 1000) + "-" + file.name
      );

      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log("error", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            // setformData((prevState) => ({
            //   ...prevState,
            //   frontRead: downloadURL,
            // }));
            setValue("idFront", downloadURL);
            clearErrors("idFront");
          });
        }
      );
    } else {
      setError("idFront", { type: "required" });
      setValue("idFront", "");
    }
  };

  const fileBackUpload = (file) => {
    if (file?.name) {
      var dateTime = Date.now();

      const storageRef = ref(
        storage,
        "images/kycDocuments/" + Math.floor(dateTime / 1000) + "-" + file.name
      );

      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log("error", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            // setformData((prevState) => ({
            //   ...prevState,
            //   backRead: downloadURL,
            // }));
            setValue("idBack", downloadURL);
            clearErrors("idBack");
          });
        }
      );
    } else {
      setError("idBack", { type: "required" });
      setValue("idBack", "");
    }
  };

  const formSubmit = async (data) => {
    setloader(true);
    const formdata = JSON.parse(localStorage.getItem("kycformData"));
    if (formdata?.docID) {
      console.log(formdata);
      const docref = updateDocRef(dbName, formdata.docID);
      if (docref) {
        const docSnap = (await getDoc(docref)).data();
        // const docSnap = await getDoc(docref);
        const respo = await updateDoc(docref, {
          ...data,
          stepComplete: docSnap.stepComplete > 4 ? docSnap.stepComplete : 4,
        });
        console.log("res", respo);
        localStorage.setItem(
          localStorageFormData,
          JSON.stringify({ ...formdata, IDProof: data })
        );
        onSubmit("AddressProof");
      }
    }
    setloader(false);
  };

  return (
    <div className="partOne ">
      <h1 className="text-white">ID Proof</h1>
      <Form.Group as={Col} controlId="formGridState" className="mb-3 ">
        <Form.Label className="formLabel">
          Type <span className="spanTag">*</span>
        </Form.Label>
        {/* <Field name="type">
            {(props) => ( */}
        <Form.Select
          placeholder="Choose"
          required
          {...register("idType", { required: true })}
          isInvalid={errors.idType?.type === "required" ? true : false}
          // value={formData.type}
          // onChange={(val) => {
          //   setformData((prevstate) => ({
          //     ...prevstate,
          //     type: val,
          //   }));
          // }}
        >
          {idTypes.length > 0 &&
            idTypes.map((item) => {
              return (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              );
            })}
        </Form.Select>
        {/* )}
          </Field> */}

        <Form.Control.Feedback type="invalid">
          Please Select Valid ID Type
        </Form.Control.Feedback>
      </Form.Group>
      <Row>
        <Col>
          <Controller
            name="idFront"
            control={control}
            rules={{ required: true }}
            render={(props) => {
              console.log(props);
              const { field } = props;
              return (
                <Form.Group controlId="formFile" className="mb-3 ">
                  <Form.Label className="formLabel">
                    Front <span className="spanTag">*</span>
                  </Form.Label>

                  <Form.Control
                    type="file"
                    // style={{ display: "none" }}
                    required
                    isInvalid={
                      errors.idFront?.type === "required" ? true : false
                    }
                    accept="image/*,.pdf"
                    // {...register("addressFront", {
                    //   required: true,
                    //   onChange: (e) => fileAddressFrontUpload(e),
                    // })}
                    onChange={(e) => fileFrontUpload(e.target.files[0])}
                  />
                  {field.value && (
                    <div className="text-end">
                      <a
                        href={field.value}
                        className="text-white"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View File
                      </a>
                    </div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    Please Upload a Valid File
                  </Form.Control.Feedback>
                </Form.Group>
              );
            }}
          />
        </Col>
        <Col>
          <Controller
            name="idBack"
            control={control}
            rules={{ required: true }}
            render={(props) => {
              console.log(props);
              const { field } = props;
              return (
                <Form.Group controlId="formFile" className="mb-3 ">
                  <Form.Label className="formLabel">
                    Back <span className="spanTag">*</span>
                  </Form.Label>

                  <Form.Control
                    type="file"
                    // style={{ display: "none" }}
                    required
                    ref={field.ref}
                    onChange={(e) => fileBackUpload(e.target.files[0])}
                    accept="image/*,.pdf"
                    isInvalid={
                      errors.idBack?.type === "required" ? true : false
                    }
                    // {...register("addressBack", {
                    //   required: true,
                    //   // onChange: (e) => fileAddressBackUpload(e.target.files[0]),
                    // })}
                  />
                  {field.value && (
                    <div className="text-end">
                      <a
                        href={field.value}
                        className="text-white"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View File
                      </a>
                    </div>
                  )}

                  <Form.Control.Feedback type="invalid">
                    Please Upload a Valid File
                  </Form.Control.Feedback>
                </Form.Group>
              );
            }}
          />
        </Col>
      </Row>
      {/* <Form.Group controlId="formFile" className="mb-3 ">
        <Form.Label className="formLabel">
          Front <span className="spanTag">*</span>
        </Form.Label>
       
        <Form.Control
          type="file"
          required
          onChange={(e) => fileFrontUpload(e.target.files[0])}
          accept="image/*,.pdf"
          isInvalid={errors.frontRead?.type === "required" ? true : false}
        />

        <Form.Control.Feedback type="invalid">
          Please Upload a Valid File
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formFile" className="mb-3 ">
        <Form.Label className="formLabel">
          Back <span className="spanTag">*</span>
        </Form.Label>

        <Form.Control
          type="file"
          required
          onChange={(e) => fileBackUpload(e.target.files[0])}
          accept="image/*,.pdf"
          isInvalid={errors.backRead?.type === "required" ? true : false}
        />

        <Form.Control.Feedback type="invalid">
          Please Upload a Valid File
        </Form.Control.Feedback>
      </Form.Group> */}
      <Form.Group className="mb-3" controlId="formGroupPassword">
        <Form.Label className="formLabel">
          ID Number <span className="spanTag">*</span>
        </Form.Label>
        {/* <Field name="idNumber">
            {(props) => ( */}
        <Form.Control
          type="text"
          placeholder="Enter ID Number"
          // value={formData.idNumber}
          required
          // onChange={(val) => {
          //   setformData((prevstate) => ({
          //     ...prevstate,
          //     idNumber: val,
          //   }));
          // }}
          {...register("idNumber", { required: true })}
          isInvalid={errors.idNumber?.type === "required" ? true : false}
        />
        {/* )}
          </Field> */}
        <Form.Control.Feedback type="invalid">
          Please Enter Valid ID Number
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGroupPassword">
        <Form.Label className="formLabel">
          ID Issued By(Country)<span className="spanTag">*</span>
        </Form.Label>
        {/* <Field name="country">
            {(props) => ( */}
        <Form.Select
          placeholder="Choose"
          required
          // value={formData.country}
          // onChange={(val) => {
          //   setformData((prevstate) => ({
          //     ...prevstate,
          //     country: val,
          //   }));
          // }}
          {...register("idCountry", { required: true })}
          isInvalid={errors.idCountry?.type === "required" ? true : false}
        >
          {countries.length > 0 &&
            countries.map((item) => {
              return (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              );
            })}
        </Form.Select>
        {/* )}
          </Field> */}
        <Form.Control.Feedback type="invalid">
          Please Select Valid Country
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="formLabel">
          ID Issued Date (DD/MM/YYYY)<span className="spanTag">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          placeholder="Enter Issued Date"
          // value={formData.dateOfIssue}
          required
          max={moment().format("yyyy-MM-DD")}
          // onChange={(val) => {
          //   setformData((prevState) => ({
          //     ...prevState,
          //     dateOfIssue: val,
          //   }));
          // }}
          {...register("idIssue", { required: true })}
          isInvalid={errors.idIssue?.type === "required" ? true : false}
        />
        <Form.Control.Feedback type="invalid">
          Please Select Issued Date
        </Form.Control.Feedback>
        {/* <Field name="dateOfIssue">
            {(props) => (
              <DatePicker
                selected={props.input.value}
                required
                maxDate={new Date()}
                placeholderText="MM/DD/YYYY"
                onBlur={(event) => blurIssueError(event.target.value)}
                onFocus={() => setIssueError(false)}
                isClearable={true}
                className="datePick"
                onChange={props.input.onChange}
              />
            )}
          </Field> */}
        {/* {issueError && (
          <p
            style={{
              color: "#dc3545",
              textAlign: "initial",
              fontSize: 15,
              marginTop: 2,
            }}
          >
            Please Select a Valid Date
          </p>
        )} */}
      </Form.Group>
      <Form.Group className="mb-3 ">
        <Form.Label className="formLabel">
          ID Expiry Date (DD/MM/YYYY)<span className="spanTag">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          placeholder="Enter Expiry Date"
          // value={formData.dateOfExpiry}
          required
          min={moment().format("yyyy-MM-DD")}
          // onChange={(val) => {
          //   setformData((prevState) => ({
          //     ...prevState,
          //     dateOfExpiry: val,
          //   }));
          // }}
          {...register("idExpiry", { required: true })}
          isInvalid={errors.idExpiry?.type === "required" ? true : false}
        />
        <Form.Control.Feedback type="invalid">
          Please Select Expiry Date
        </Form.Control.Feedback>
        {/* <Field name="dateOfExpiry">
            {(props) => (
              <DatePicker
                selected={props.input.value}
                required
                placeholderText="MM/DD/YYYY"
                onBlur={(event) => blurExpiryError(event.target.value)}
                isClearable={true}
                onFocus={() => setExpiryError(false)}
                className="datePick"
                onChange={props.input.onChange}
              />
            )}
          </Field> */}

        {/* {expiryError && (
          <p
            style={{
              color: "#dc3545",
              textAlign: "initial",
              fontSize: 15,
              marginTop: 2,
            }}
          >
            Please Select a Valid Date
          </p>
        )} */}
      </Form.Group>
      <div className="d-flex align-items-center justify-content-between">
        <Button variant="primary" onClick={() => onSubmit(backform)}>
          Previous
        </Button>
        <Button
          variant="primary"
          disabled={loader}
          onClick={handleSubmit(formSubmit)}
        >
          {loader ? (
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          ) : (
            <span> Save & Next</span>
          )}
        </Button>
      </div>
    </div>
  );
};

export default IDProof;
