import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./components/home";
// import MainForm from "./components/common/mainForm";
import "bootstrap/dist/css/bootstrap.min.css";
// import NewForm from "./components/newform/NewForm";
import Form from "./components/formnew/Form";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            index
            element={
              <div className="App-header">
                <Home />
              </div>
            }
          />
          <Route path="card-forms" element={<Form />} />
          <Route
            path="kyc-forms"
            element={<Navigate to="/card-forms" replace={true} />}
          />
          {/* <Route path="new-kyc-forms" element={<Form />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
