import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { kycDB, updateDocRef } from "../../firebase_setup/firebase";
import {
  addDoc,
  updateDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore/lite";
import { dbName } from "./constant";

function Basicinfo({ onSubmit }) {
  const [basicForm, setbasicForm] = useState({});
  const [loader, setloader] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ values: basicForm });

  useEffect(() => {
    const formdata = JSON.parse(localStorage.getItem("kycformData"));

    if (formdata?.personal) {
      setbasicForm(formdata?.personal);
    }
  }, []);

  const formSubmit = async (data) => {
    // onSubmit("Address");
    data.email = data.email.toLowerCase();
    setloader(true);
    const formdata = JSON.parse(localStorage.getItem("kycformData"));
    if (formdata?.docID) {
      console.log(formdata);
      const docref = updateDocRef(dbName, formdata.docID);
      if (docref) {
        const docSnap = (await getDoc(docref)).data();
        const respo = await updateDoc(docref, {
          ...data,
          stepComplete: docSnap.stepComplete > 1 ? docSnap.stepComplete : 1,
        });
        console.log("res", respo);
        localStorage.setItem(
          "kycformData",
          JSON.stringify({ ...formdata, personal: data })
        );
        onSubmit("Address");
      }
    } else {
      const resp = await addDoc(kycDB, {
        ...data,
        status: 0,
        stepComplete: 1,
        createdAt: serverTimestamp(),
      });
      console.log("res", resp.id);
      if (resp) {
        localStorage.setItem(
          "kycformData",
          JSON.stringify({ ...formdata, personal: data, docID: resp.id })
        );
        onSubmit("Address");
      }
    }
    setloader(false);
  };

  return (
    <div className=" partOne">
      <h1 className="text-white">Personal Info</h1>
      {/* <form onSubmit={handleSubmit(formSubmit)}> */}
      <Form.Group className="mb-3" controlId="formGroupName">
        <Form.Label className="formLabel">
          Name
          <span className="spanTag">
            <span className="spanTag">*</span>
          </span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Name"
          // value={basicForm.name}
          isInvalid={errors.name?.type === "required" ? true : false}
          required
          {...register("name", { required: true })}
          // onChange={(val) => {
          //   console.log(val);
          //   setbasicForm((prevState) => ({
          //     ...prevState,
          //     name: val.target.value,
          //   }));
          // }}
        />
        {errors.name?.type === "required" && (
          <Form.Control.Feedback type="invalid">
            Please Enter Full Name
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label className="formLabel">
          Email ID{" "}
          <span className="spanTag">
            <span className="spanTag">*</span>
          </span>
        </Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter Email"
          {...register("email", { required: true })}
          // value={basicForm.email}
          isInvalid={errors.email?.type === "required" ? true : false}
          required
          // onChange={(val) => {
          //   setbasicForm((prevState) => ({
          //     ...prevState,
          //     email: val.target.value,
          //   }));
          // }}
        />
        {errors.email?.type === "required" && (
          <Form.Control.Feedback type="invalid">
            Please Enter a Valid Email
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGroupPhone">
        <Form.Label className="formLabel">
          Phone Number(With Country Code) <span className="spanTag">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Mobile Number"
          required
          isInvalid={errors.phoneNumber ? true : false}
          {...register("phoneNumber", {
            required: true,
            maxLength: 13,
            pattern: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
          })}
          // value={basicForm.phoneNumber}
          // onChange={(val) => {
          //   setbasicForm((prevState) => ({
          //     ...prevState,
          //     phoneNumber: val.target.value,
          //   }));
          // }}
        />
        {errors.phoneNumber && (
          <Form.Control.Feedback type="invalid">
            Please Enter Valid Phone Number
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="formLabel">
          Date of Birth (DD/MM/YYYY)<span className="spanTag">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          max={moment().format("yyyy-MM-DD")}
          placeholder="Enter Date of Birth"
          {...register("dateofBirth", {
            required: true,
          })}
          isInvalid={errors.dateofBirth?.type === "required" ? true : false}
          required
        />
        {errors.dateofBirth?.type === "required" && (
          <Form.Control.Feedback type="invalid">
            Please Enter Date of Birth
          </Form.Control.Feedback>
        )}
      </Form.Group>
      {/* <input type="submit" /> */}

      <div className="d-flex align-items-center justify-content-between mt-4">
        <Button variant="primary" onClick={() => onSubmit("Intro")}>
          Previous
        </Button>
        <Button
          variant="primary"
          type="submit"
          disabled={loader}
          onClick={handleSubmit(formSubmit)}
        >
          {loader ? (
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          ) : (
            <span> Save & Next</span>
          )}
        </Button>
      </div>
      {/* </form> */}
    </div>
  );
}

export default Basicinfo;
