import React from "react";
import { Button } from "react-bootstrap";
// import logo from "../../assets/haute.png";
import btclogo from "../../assets/btc.png";

const IntroInfo = ({ onSubmit }) => {
  return (
    <div className=" partOne">
      <div className="pt-2 pb-4">
        <a href="/">
          <img src={btclogo} width={150} alt="Logo" />
        </a>
      </div>
      <h1 className="mb-4 text-white">Intro Info</h1>
      <h4 className="text-white">
        ID (License, passport or government issued ID)
      </h4>
      <div className="mb-5 text-white">
        <ul className="list-unstyled text-start">
          <li>Must be a Passport, Driver's License or National ID.</li>
          <li>Address must match your P.O.A.</li>
          <li>Must be a current ID and not expired.</li>
          <li>
            All corners of the document must be visible within the frame (No
            cropping but must have a small portion of background showing).
          </li>
          <li>
            Passports must have the top and bottom visible fully open and flat.
          </li>
          <li>
            The information on the document must be clearly legible with no
            glare or shadows.
          </li>
        </ul>
      </div>
      <h4 className="text-white">P.O.A (Proof of Current Personal Address)</h4>
      <div className="text-white">
        <ul className="list-unstyled text-start">
          <li>
            Must be a photo or PDF (not a screenshot) of a household utility
            bill (gas, electric, water, landline or broadband only), tax bill or
            bank statement.
          </li>
          <li>Must be dated visibly within 3 months.</li>
          <li>Must show your full name and full CURRENT address.</li>
          <li>Must show the biller's info.</li>
        </ul>
      </div>
      <div className="float-end">
        <Button
          variant="primary"
          onClick={() => {
            onSubmit("Basic");
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default IntroInfo;
