import { Button, Image } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";
// import hauteLogo from "../assets/applogo.png";
import btc from "../assets/btc.png";

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <Image src={btc} width={180} />
      <Button
        className="homeButton sectionGap"
        onClick={() => navigate("/card-forms")}
      >
        Card Order Form
      </Button>
      {/* <Button
        className="homeButton sectionGap"
        onClick={() => navigate("/new-kyc-forms")}
      >
        New KYC Forms
      </Button> */}
    </>
  );
};

export default Home;
