import { Button, Col, Form, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { addressTypes } from "../common/countries";
import { useForm, Controller } from "react-hook-form";
import { dbName, localStorageFormData } from "./constant";
import { updateDocRef } from "../../firebase_setup/firebase";
import { getDoc, updateDoc } from "firebase/firestore/lite";
import Modal from "react-bootstrap/Modal";

// import { Field } from "react-final-form";
const AddressProof = ({ onSubmit, handleClearForm }) => {
  const [formData, setformData] = useState({});
  const [loader, setloader] = useState(false);

  const [modalShow, setmodalShow] = useState(false);

  const {
    register,
    setValue,
    setError,
    clearErrors,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ values: formData });

  // useEffect(() => {
  //   const formdata = JSON.parse(localStorage.getItem("kycformData"));
  //   console.log("formData", formData);
  //   if (formdata?.addressproof) {
  //     setbackform(
  //       formdata.shipaddress === "yes" ? "ShippingAddress" : "Address"
  //     );
  //     setformData(formdata?.addressproof);
  //   }
  // }, []);

  useEffect(() => {
    const formdata = JSON.parse(localStorage.getItem("kycformData"));
    if (formdata?.addressproof) {
      setformData(formdata?.addressproof);
    }
  }, []);

  const storage = getStorage();
  const fileAddressFrontUpload = (file) => {
    if (file?.name) {
      var dateTime = Date.now();

      const storageRef = ref(
        storage,
        "images/kycDocuments/" + Math.floor(dateTime / 1000) + "-" + file.name
      );

      const uploadTask = uploadBytesResumable(storageRef, file);
      //   console.log("uploadTaskuploadTask", uploadTask);
      uploadTask.on(
        "state_changed",
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log("error", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            setValue("addressFront", downloadURL);
            clearErrors("addressFront");
          });
        }
      );
    } else {
      setError("addressFront", { type: "required" });
      setValue("addressFront", "");
    }

    console.log(errors);
    // setValue("addressFront", files.target.value);
  };

  const fileAddressBackUpload = (file) => {
    if (file?.name) {
      var dateTime = Date.now();

      const storageRef = ref(
        storage,
        "images/kycDocuments/" + Math.floor(dateTime / 1000) + "-" + file.name
      );

      const uploadTask = uploadBytesResumable(storageRef, file);
      //   console.log("uploadTaskuploadTask", uploadTask);
      uploadTask.on(
        "state_changed",
        function (snapshot) {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log("error", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at", downloadURL);
            setValue("addressBack", downloadURL);
            clearErrors("addressBack");
            // setformData((prevState) => ({
            //   ...prevState,
            //   addressBack: downloadURL,
            // }));
            // setAddressBackRead(downloadURL);
          });
        }
      );
    } else {
      setError("addressBack", { type: "required" });
      setValue("addressBack", "");
    }
  };

  const formSubmit = async (data) => {
    setloader(true);
    const formdata = JSON.parse(localStorage.getItem(localStorageFormData));
    if (formdata?.docID) {
      console.log(formdata);
      const docref = updateDocRef(dbName, formdata.docID);
      if (docref) {
        const docSnap = (await getDoc(docref)).data();
        // const docSnap = await getDoc(docref);
        const respo = await updateDoc(docref, {
          ...data,
          stepComplete: docSnap.stepComplete > 5 ? docSnap.stepComplete : 5,
        });
        console.log("res", respo);
        localStorage.setItem(
          localStorageFormData,
          JSON.stringify({ ...formdata, addressproof: data })
        );
        setmodalShow(true);
      }
    }
    setloader(false);
  };

  console.log("err", errors);

  const handleClose = () => {
    setmodalShow(false);
    handleClearForm();
  };

  return (
    <div className="partOne ">
      <h1 className="text-white">Address Proof</h1>
      <Form.Group as={Col} controlId="formGridState" className="mb-3 ">
        <Form.Label className="formLabel">
          Type <span className="spanTag">*</span>
        </Form.Label>
        {/* <Field name="addressType"> */}
        {/* {(props) => ( */}
        <Form.Select
          placeholder="Choose"
          required
          {...register("addressType", { required: true })}
          isInvalid={errors.addressType?.type === "required" ? true : false}
          // value={formData.addressType}
          // onChange={(val) => {
          //   setformData((prevState) => ({
          //     ...prevState,
          //     addressType: val,
          //   }));
          // }}
        >
          {addressTypes.length > 0 &&
            addressTypes.map((item) => {
              return (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              );
            })}
        </Form.Select>
        {/* )} */}
        {/* </Field> */}
        <Form.Control.Feedback type="invalid">
          Please Select Valid Type
        </Form.Control.Feedback>
      </Form.Group>
      <Row>
        <Col>
          <Controller
            name="addressFront"
            control={control}
            rules={{ required: true }}
            render={(props) => {
              console.log(props);
              const { field } = props;
              return (
                <Form.Group controlId="formFile" className="mb-3 ">
                  <Form.Label className="formLabel">
                    Front <span className="spanTag">*</span>
                  </Form.Label>

                  <Form.Control
                    type="file"
                    // style={{ display: "none" }}
                    required
                    isInvalid={
                      errors.addressFront?.type === "required" ? true : false
                    }
                    accept="image/*,.pdf"
                    // {...register("addressFront", {
                    //   required: true,
                    //   onChange: (e) => fileAddressFrontUpload(e),
                    // })}
                    onChange={(e) => fileAddressFrontUpload(e.target.files[0])}
                  />
                  {field.value && (
                    <div className="text-end">
                      <a
                        href={field.value}
                        className="text-white"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View File
                      </a>
                    </div>
                  )}
                  <Form.Control.Feedback type="invalid">
                    Please Upload a Valid File
                  </Form.Control.Feedback>
                </Form.Group>
              );
            }}
          />
          {/* //{" "}
          <div>
            // <img src="" />
            //{" "}
          </div> */}
        </Col>
        <Col>
          <Controller
            name="addressBack"
            control={control}
            rules={{ required: true }}
            render={(props) => {
              console.log(props);
              const { field } = props;
              return (
                <Form.Group controlId="formFile" className="mb-3 ">
                  <Form.Label className="formLabel">
                    Back <span className="spanTag">*</span>
                  </Form.Label>

                  <Form.Control
                    type="file"
                    // style={{ display: "none" }}
                    required
                    ref={field.ref}
                    onChange={(e) => fileAddressBackUpload(e.target.files[0])}
                    accept="image/*,.pdf"
                    isInvalid={
                      errors.addressBack?.type === "required" ? true : false
                    }
                    // {...register("addressBack", {
                    //   required: true,
                    //   // onChange: (e) => fileAddressBackUpload(e.target.files[0]),
                    // })}
                  />
                  {field.value && (
                    <div className="text-end">
                      <a
                        href={field.value}
                        className="text-white"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View File
                      </a>
                    </div>
                  )}

                  <Form.Control.Feedback type="invalid">
                    Please Upload a Valid File
                  </Form.Control.Feedback>
                </Form.Group>
              );
            }}
          />
        </Col>
      </Row>

      <Form.Group className="mb-3 " controlId="formGroupPassword">
        <Form.Label className="formLabel">
          Issued By<span className="spanTag">*</span>
        </Form.Label>
        {/* <Field name="addressCountry">
            {(props) => ( */}
        <Form.Control
          type="text"
          placeholder="Enter Issued By"
          // value={basicForm.name}
          isInvalid={
            errors.addressIssuedCountry?.type === "required" ? true : false
          }
          required
          {...register("addressIssuedCountry", { required: true })}
          // onChange={(val) => {
          //   console.log(val);
          //   setbasicForm((prevState) => ({
          //     ...prevState,
          //     name: val.target.value,
          //   }));
          // }}
        />
        {/* <Form.Select
          placeholder="Choose"
          required
          {...register("addressIssuedCountry", { required: true })}
          isInvalid={
            errors.addressIssuedCountry?.type === "required" ? true : false
          }
          // value={formData.addressCountry}
          // onChange={(val) => {
          //   setformData((prevState) => ({
          //     ...prevState,
          //     addressBack: val,
          //   }));
          // }}
        >
          {countries.length > 0 &&
            countries.map((item) => {
              return (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              );
            })}
        </Form.Select> */}
        {/* )}
          </Field> */}
        <Form.Control.Feedback type="invalid">
          Please Select Valid Country
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3 ">
        <Form.Label className="formLabel">
          ID Issued Date (DD/MM/YYYY)<span className="spanTag">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          placeholder="Enter Date of Birth"
          // value={formData.addressDateOfIssue}
          required
          // max={new Date()}
          // min={moment().format("yyyy-MM-DD")}
          // onChange={(val) => {
          //   setformData((prevState) => ({
          //     ...prevState,
          //     addressDateOfIssue: val,
          //   }));
          // }}
          {...register("addressIssue", { required: true })}
          isInvalid={errors.addressIssue?.type === "required" ? true : false}
        />

        <Form.Control.Feedback type="invalid">
          Please Select Expiry Date
        </Form.Control.Feedback>

        {/* {error && (
          <p
            style={{
              color: "#dc3545",
              textAlign: "initial",
              fontSize: 15,
              marginTop: 2,
            }}
          >
            Please Select a Valid Date
          </p>
        )} */}
      </Form.Group>
      <div className="d-flex align-items-center justify-content-between">
        <Button variant="primary" onClick={() => onSubmit("IDProof")}>
          Previous
        </Button>
        <Button
          variant="primary"
          disabled={loader}
          onClick={handleSubmit(formSubmit)}
        >
          {loader ? (
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          ) : (
            <span> Save</span>
          )}
        </Button>
      </div>
      <Modal show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Successfully Submitted.</Modal.Title>
        </Modal.Header>
        <Modal.Body>We received your request, Thank you!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddressProof;
