import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { countries } from "../common/countries";
import { useForm } from "react-hook-form";
import { updateDocRef } from "../../firebase_setup/firebase";
import { dbName } from "./constant";
import { getDoc, updateDoc } from "firebase/firestore/lite";

function Addressinfo({ onSubmit }) {
  const [addressInfo, setaddressInfo] = useState({});
  const [loader, setloader] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ values: addressInfo });

  useEffect(() => {
    const formdata = JSON.parse(localStorage.getItem("kycformData"));

    if (formdata?.addressInfo) {
      setaddressInfo(formdata?.addressInfo);
    }
  }, []);

  // console.log("addressInfo", addressInfo);

  const formSubmit = async (data) => {
    // onSubmit("Address");
    setloader(true);
    const formdata = JSON.parse(localStorage.getItem("kycformData"));
    if (formdata?.docID) {
      console.log(formdata);
      const docref = updateDocRef(dbName, formdata.docID);
      if (docref) {
        const docSnap = (await getDoc(docref)).data();
        const respo = await updateDoc(docref, {
          address: data,
          shipaddress: data.shipaddress,
          stepComplete: docSnap.stepComplete > 2 ? docSnap.stepComplete : 2,
        });
        console.log("res", respo);
        localStorage.setItem(
          "kycformData",
          JSON.stringify({
            ...formdata,
            addressInfo: data,
            shipaddress: data.shipaddress,
          })
        );
        onSubmit(data.shipaddress === "yes" ? "IDProof" : "ShippingAddress");
      }
    }
    setloader(false);
  };

  return (
    <div className="partOne ">
      <h1 className="text-white">Address Info</h1>
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label className="formLabel">
          Address Line 1<span className="spanTag">*</span>
        </Form.Label>
        <Form.Control
          type="text"
          required
          name="addressLine1"
          //   value={""}
          // onChange={(val) => {
          //   console.log(val.target.value);
          //   setaddressInfo((prevState) => ({
          //     ...prevState,
          //     address1: val.target.value,
          //   }));
          // }}
          isInvalid={errors.addressLine1?.type === "required" ? true : false}
          {...register("addressLine1", { required: true })}
          placeholder="Enter address line 1"
        />
        {errors.addressLine1?.type === "required" && (
          <Form.Control.Feedback type="invalid">
            Please Enter Address Line 1
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="formGridAddress2">
        <Form.Label className="formLabel">Address Line 2</Form.Label>
        <Form.Control
          name="addressLine2"
          isInvalid={errors.addressLine2?.type === "required" ? true : false}
          {...register("addressLine2")}
          // value={addressInfo.adrline2}
          // onChange={(val) => {
          //   setaddressInfo((prevState) => ({
          //     ...prevState,
          //     adrline2: val.target.value,
          //   }));
          // }}
          placeholder="Enter address line 2"
        />
      </Form.Group>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label className="formLabel">
            City<span className="spanTag">*</span>
          </Form.Label>
          <Form.Control
            required
            // value={addressInfo.city}
            // onChange={(val) => {
            //   setaddressInfo((prevState) => ({
            //     ...prevState,
            //     city: val.target.value,
            //   }));
            // }}
            isInvalid={errors.city?.type === "required" ? true : false}
            {...register("city", { required: true })}
            placeholder="Enter City"
          />
          {errors?.city && (
            <Form.Control.Feedback type="invalid">
              Please Enter City Name
            </Form.Control.Feedback>
          )}
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState">
          <Form.Label className="formLabel">
            State<span className="spanTag">*</span>
          </Form.Label>
          <Form.Control
            required
            // value={addressInfo.state}
            // onChange={(val) => {
            //   setaddressInfo((prevState) => ({
            //     ...prevState,
            //     state: val.target.value,
            //   }));
            // }}
            isInvalid={errors.state?.type === "required" ? true : false}
            {...register("state", { required: true })}
            placeholder="Enter State"
          />
          {errors.state && (
            <Form.Control.Feedback type="invalid">
              Please Enter State
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="formGridCountry">
          <Form.Label className="formLabel">
            Country<span className="spanTag">*</span>
          </Form.Label>
          <Form.Select
            placeholder="Choose"
            required
            isInvalid={
              errors.addressInfoCountry?.type === "required" ? true : false
            }
            {...register("addressInfoCountry", { required: true })}
            // value={addressInfo.country}
            // onChange={(val) => {
            //   setaddressInfo((prevState) => ({
            //     ...prevState,
            //     country: val.target.value,
            //   }));
            // }}
          >
            {countries.length > 0 &&
              countries.map((item) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
          </Form.Select>

          <Form.Control.Feedback type="invalid">
            Please Select Valid Country
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridZip">
          <Form.Label className="formLabel">
            Zip Code<span className="spanTag">*</span>
          </Form.Label>
          <Form.Control
            required
            isInvalid={errors.zipCode?.type === "required" ? true : false}
            {...register("zipCode", { required: true })}
            // value={addressInfo.zip}
            // onChange={(val) => {
            //   setaddressInfo((prevState) => ({
            //     ...prevState,
            //     zip: val.target.value,
            //   }));
            // }}
            placeholder="Enter Zip Code"
          />
          <Form.Control.Feedback type="invalid">
            Please Enter Valid Zip Code
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Label className="formLabel mt-3">
          Same for Shipping Address<span className="spanTag">*</span>
        </Form.Label>
        <div key="inline-radio" className="mb-3 checkBoxStyle">
          <>
            <Form.Check
              inline
              required
              label="Yes"
              value={"yes"}
              {...register("shipaddress", { required: true })}
              isInvalid={errors.shipaddress?.type === "required" ? true : false}
              name="shipaddress"
              type="radio"
              id="inline-radio-1"
              // onChange={(val) => {
              //   console.log(val.target.value);
              //   setaddressInfo((prevState) => ({
              //     ...prevState,
              //     shipaddress: "yes",
              //   }));
              // }}
            />
            <Form.Check
              inline
              required
              label="No"
              value={"no"}
              name="shipaddress"
              {...register("shipaddress", { required: true })}
              isInvalid={errors.shipaddress?.type === "required" ? true : false}
              type="radio"
              // onChange={(val) => {
              //   console.log(val.target.value);
              //   setaddressInfo((prevState) => ({
              //     ...prevState,
              //     shipaddress: "no",
              //   }));
              // }}
              id="inline-radio-2"
            />
          </>
          <Form.Control.Feedback type="invalid">
            Please Select One Option
          </Form.Control.Feedback>
        </div>
      </Row>
      <div className="d-flex align-items-center justify-content-between">
        <Button variant="primary" onClick={() => onSubmit("Basic")}>
          Previous
        </Button>
        <Button
          variant="primary"
          disabled={loader}
          onClick={handleSubmit(formSubmit)}
        >
          {loader ? (
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          ) : (
            <span> Save & Next</span>
          )}
        </Button>
      </div>
    </div>
  );
}

export default Addressinfo;
